<template>
  <div class="with-anchors">
    <h1 class="display-l">Graphic Treatments</h1>

    <!-- stamp -->
    <section>
        <h2 id="stamp" class="display-m">Holding device - The Contact Stamp</h2>
        <p>Inspired by the surface area of a tire that makes contact with the road, ‘The Contact Stamp, is a device used to hold and divide content across our communications.</p>
        <p><img src="../../assets/img/graphic1.png" alt="" role="presentation" width="350"/></p><br/>

      <p>‘The Contact Stamp’ can be used in a variety of ways to hold varying amounts of content, with two rules dictating how it’s used:</p>
        <ol>
          <li>It should always face either; down, up, or to the right, never to the left.</li>
          <li>The curve should always remain consistent and never be cropped into or manipulated. Use the as shown in the examples below as a visual guide of best practice examples.</li>
        </ol>

        <p><img src="../../assets/img/graphic2.png" alt="" role="presentation" width="800"/></p><br/>
    </section>

    <!-- treatment -->  
    <section>
        <h2 id="treatment" class="display-m">Treatment</h2>
        <p class="section-heading">InteleTreatment</p>
        <p>Used to energise and brand images the ‘InteleTreatment’ can be either layered through imagery or positioned above. Appearing predominantly in Cornflower and Mint to build brand recognition. However, in secondary pages and internal communications we can colour this treatment with the tertiary palette.</p>
        <p><img src="../../assets/img/photo5.png" alt="" role="presentation" /></p><br/>

        <div class="alert">Please note, we never stack the ‘InteleTreatment’ more than four times to avoid over complicating visuals.</div>
        <p><img src="../../assets/img/photo6.png" alt="" role="presentation" /></p><br/>

        <p class="section-heading">Spotlight Treatment</p>
        <p>Used to highlight specific content and brand images. Appearing predominantly in Cornflower and Mint to build brand recognition. However, in secondary pages and internal communications we can colour this treatment with the tertiary palette.</p>
        <p><img src="../../assets/img/photo7.png" alt="" role="presentation" /></p><br/>
        
    </section>

                
    <AnchorMenu :anchors="graphicMenu"/>

  </div>
</template>

<script>
import AnchorMenu from "../common/AnchorMenu";
import { graphicAnchors } from '../../assets/data/anchors'

export default {
  name: 'GraphicTreatments',
  components: {
    AnchorMenu
  },
  data: () => {
    return {
      graphicMenu: graphicAnchors,
    };
  },
}
</script>
